<template> 
    <v-container id="login" class="text-center d-flex justify-center align-center ma-auto" style="height: fit-content" fluid>
        <v-row class="main pa-0 ma-0">
            <v-col cols="12" sm="10" md="7" lg="5" class="ma-auto pa-0"> 
                <v-card class="ma-0 pa-0"  outlined>
                    <div class="ma-0 pa-0 py-6">
                        <v-img
                            class="d-block pa-0"
                            height="100"
                            contain
                            :src="require('../../assets/images/logo.png')"
                        ></v-img>
                    </div>
                    <v-form ref="form" class="pa-6" v-model="valid" lazy-validation @submit.prevent="login">
                            <v-text-field
                                v-model="user.username"
                                type='text'
                                :label="$t('username')"
                                prepend-inner-icon="mdi-account"
                                outlined
                                required
                                :counter="maxTextfieldLength"
                                :rules="textfieldRules"
                                autocomplete="off"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-text-field
                                v-model="user.password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                :label="$t('password')"
                                prepend-inner-icon="mdi-lock"
                                outlined
                                required
                                :counter="maxTextfieldLength"
                                :rules="textfieldRules"
                                autocomplete="off"
                                @click:append="show = !show"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-btn type="submit" depressed :disabled="!valid" class="pa-auto ma-auto success custom-accent">
                                <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                                <span v-else>{{$t("login")}}</span>
                            </v-btn>
                        </v-form>             
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions,mapState } from "vuex";

export default({
    name: 'Login',
    data() {
        return {
            user:{},
            show:false,
            valid: false,
        }
    },
    computed: {
        maxTextfieldLength () {
        return 30;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapState({
          login_loading: "loading"
        }),
        loading(){
            return this.login_loading;
        },
    },
    methods: {
        login() {
            if(this.$refs.form.validate()){
                this.retrieveToken(this.user).then(() => {
                    this.retrieveUserInfo().then((response)=>{
                        this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t("welcome")+', '+response.data.name.toUpperCase()+'!', type:'success'});
                        this.$router.push({ name: 'admin' });
                    })
                }).catch(() => {
                    this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t("loginError"), type:'error'});
                });
            }
        },

        ...mapActions(["retrieveToken","retrieveUserInfo"]),
    }
})
</script>
<style scoped>
</style>
